@import "../main/abstracts/index";

.content--info-box-custom,
.section--info-box-custom{

    .info-box-custom{
        --bs-gutter-y: 45px;

        @include media-breakpoint-down(lg) {
            --bs-gutter-y: 36px;
            --bs-gutter-x: 16px;
        }

        &__item{
            position: relative;
            aspect-ratio: 16 / 9;
            display: flex;
            align-items: flex-end;
            z-index: 0;

            &--has-link{

                a.stretched-link{

                    @include hover-focus-within{
                        //text-decoration: underline;

                        .info-box-custom{

                            &__arrow{
                                left: calc(50% - 12px - 50px);

                                @include media-breakpoint-down(xl) {
                                    left: calc(66.66% - 12px - 50px);
                                }

                                @include media-breakpoint-down(lg) {
                                    left: calc(100% - 40px);
                                }
                            }
                        }
                    }
                }

                @include hover-focus-within{

                    .info-box-custom{

                        &__caption{
                            color:$color__white!important;
                            background-color: $color__primary;

                            a,
                            p a,
                            h3 a{
                                color:$color__white!important;
                            }

                            .text-phone-icon:before{
                                filter: $filter__white;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                display: block;
            }
        }

        &__image{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            overflow: hidden;

            img{
                object-fit: cover;
            }

            @include media-breakpoint-down(lg) {
                position: relative;
                top: auto;
                left: auto;
            }
        }

        &__caption{
            width:calc(50% - 12px);
            color: $color__font;
            background-color: $color__tertiary;
            padding: 25px 28px 35px 28px;
            transition: all .25s ease-in-out;

            h3{
                color: $color__secondary!important;
                margin-bottom: 5px;

                a{
                    color: $color__secondary!important;
                    font-weight: $font__weight--normal;
                    text-decoration: none;
                    transition: all .25s ease-in-out;
                }
            }

            p {
                a{
                    color: $color__black!important;
                    font-weight: $font__weight--semibold;
                    position: relative;
                    z-index: 1;
                    transition: all .25s ease-in-out;
                }

                img[src*=".svg"]{
                    min-height: 40px;
                }
            }

            .text-phone-icon{
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;

                &:before {
                    content: "";
                    display: inline-block;
                    margin-right: 6px;
                    width: 38px;
                    height: 38px;
                    border: 0;
                    transition: all .25s ease-in-out;
                    background: {
                        color: transparent;
                        image: url(/bundles/hitcomfrontend/img/material-symbols/400/outlined/perm_phone_msg.svg);
                        position: 0 0;
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
            }

            @include media-breakpoint-down(xl) {
                width:calc(66.66% - 12px);
                padding: 17px 20px 33px 20px;
            }

            @include media-breakpoint-down(lg) {
                width:100%;
                padding-bottom: 30px;

                .text-phone-icon:before{
                    width: 25px;
                    height: 25px;
                }
            }
        }

        &__arrow{
            position: absolute !important;
            left: calc(50% - 12px - 50px - 20px);
            bottom: -25px;

            height: 50px;
            width: 50px;

            border: 13px solid $color__primary;
            color: $color__white;
            background-color: $color__primary;

            margin: 0;
            box-shadow: 0 2px 8px rgba(0,0,0,.4);
            transition: all .25s ease-in-out;

            img{
                filter: $filter__white;
            }

            @include media-breakpoint-down(xl) {
                left:calc(66.66% - 12px - 50px - 20px);
            }

            @include media-breakpoint-down(lg) {
                left: calc(100% - 40px - 20px);
                bottom: -20px;

                height: 40px;
                width: 40px;
                border-width: 8px;
            }
        }
    }

    &.section--bg-default,
    &.section--bg-light{

        .info-box-custom__home{

            &.col-lg-3 .info-box-custom__item--textbox-white{
                border: 1px solid $color__primary;
            }

            &.col-md-6 .info-box-custom__item--textbox-white .info-box-custom__caption{
                border: 1px solid $color__primary;
            }
        }
    }

    &.section--bg-dark{
        header h2{
            color: $color__white;
        }
    }

    .info-box-custom__home{

        &.col-md-6 .info-box-custom__image{

            @include media-breakpoint-down(lg) {
                height: auto;
            }

            *{
                @include media-breakpoint-up(lg) {
                    width: 100%;
                    height: 100%;
                    max-width: unset;
                }
            }
        }

        .info-box-custom{

            &__item{
                height: 100%;
                min-height: 350px;
                aspect-ratio: unset;

                transition: all .25s ease-in-out;
                background-color: $color__white;

                &--textbox-yellow{
                    background-color: $color__tertiary;

                    .info-box-custom__caption{
                        background-color: $color__tertiary;
                    }
                }

                &--has-link{

                    @include hover-focus-within{
                        color:$color__white!important;
                        background-color: $color__primary!important;
                    }
                }

                @include media-breakpoint-down(lg) {
                    min-height: 125px;
                }

                @include media-breakpoint-down(sm) {
                    min-height: 100px;
                }

                @media (max-width: 450px) {
                    min-height: unset;
                }
            }

            &__caption{
                background-color: $color__white;
            }
        }
    }

    .col-lg-3{

        .info-box-custom{

            &__item{
                //aspect-ratio: unset;
                height: 100%;
                display: block;

                &--has-link a.stretched-link{

                    @include hover-focus-within{
                        //text-decoration: underline;

                        .info-box-custom__arrow{
                            left: calc(100% - 50px);
                        }
                    }
                }
            }

            &__image{
                position: relative;
                top: auto;
                left: auto;
                width: 100%;
                height: auto;
                z-index: 0;
                overflow: hidden;

                img{
                    min-height: 180px;
                }
            }

            &__caption{
                width: 100%;
                background-color: transparent!important;
            }

            &__arrow{
                left: calc(100% - 20px - 50px);
            }
        }
    }
}
